
































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {
    BDropdown,
    BIcon,
    BIconArrowLeftSquare,
    BIconArrowRightSquare,
    BIconCheckCircle,
    BIconPlusCircle,
    BIconServer,
    BIconXCircleFill
} from 'bootstrap-vue'
import CClarifyIncomeAddModal from "@/modules/budget-clarify/components/ClarifyIncomeAddModal.vue";
import {genListOfYears, listForCorrect, variantName} from "@/modules/budget/budgetCorrectHelper";
import VueElementLoading from 'vue-element-loading';
import {
    budget_level_of_region_code,
    BudgetIncomeCorrectRow,
    BudgetIncomeData,
    EbkIncomeCodeRequest,
    FilterEventOwner,
    IncomeCorrectData,
    IncomeCorrectRowClass,
    IncomeCorrectSaveData, noAbc
} from './CorectHelper'
import {loadVariantByUiid} from "@/modules/budget/budgetVariantService";


@Component({
    methods: {budget_level_of_region_code, noAbc},
    components: {
        BIconPlusCircle,
        'c-add-modal': CClarifyIncomeAddModal,
        'b-icon': BIcon,
        'b-icon-check-circle': BIconCheckCircle,
        'left-icon': BIconArrowLeftSquare,
        'right-icon': BIconArrowRightSquare,
        'save-icon': BIconServer,
        'loading': VueElementLoading,
        BIconXCircleFill,
    }
})

export default class CostCorrectSpf extends Vue {
    private correct_data: IncomeCorrectData = new IncomeCorrectData();
    private addShow: boolean = false;
    private region: string = '';
    private repYear: number = new Date().getFullYear();
    private variant: string = '';
    private editable = false;
    private loading = false;
    private module_code = "004.004.002"
    private access_level = 1;

    @Prop({
        required: true,
        default: 2022
    })
    year!: number;
    @Prop({
        required: true,
        default: 55
    })
    obl!: String;
    @Prop({
        required: true,
        default: ''
    })
    reg!: String;
    @Prop({
        required: true,
        default: []
    })
    listReg: any

    @Prop({
        required: true,
        default: []
    })
    listVariants: any;

    private hide() {
        (this.$refs.drop as BDropdown).hide(true)
    }

    private do_correct_row_all() {
        for (const row of this.correct_data.rows) {
            row.do_correct_row();
        }
    }

    private save_correct_row_all() {
        for (const row of this.correct_data.rows) {
            this.save_correct_row(row, true);
        }
        this.makeToast("success", "Результат сохранения", "Сохранено");
    }

    //
    // public row_hover(item: IncomeCorrectRowClass) {
    //     this.correct_data.ebk_duplicate_rows = [];
    //     if (item.ebk_duplicates.length > 0) {
    //         this.correct_data.ebk_duplicate_rows = this.correct_data.ebk_duplicate_rows.concat(item.ebk_duplicates);
    //         this.correct_data.ebk_duplicate_rows.push(item);
    //     }
    // }

    // public row_class(item: IncomeCorrectRowClass, type: any) {
    //     if (!item || type !== 'row') return;
    //     if (this.correct_data.ebk_duplicate_rows.indexOf(item) > -1) {
    //         return "table-success";
    //     }
    // }

    private edited_row: IncomeCorrectRowClass | null = null;

    public edit_row_node(row: IncomeCorrectRowClass) {
        this.edited_row = row;
        this.$root.$emit('bv::show::modal', 'modal-income-note')
    }

    // public get_object_native_id(object: any) {
    //     return object.__ob__.dep.id;
    // }

    private async delete_correct_row(item: IncomeCorrectRowClass) {
        if (!item.is_correct_zero_and_may_deleted) {
            this.makeToast("danger", "Ошибка", "Попытка удалить не пустую строку!");
            return
        }
        const field_values = item.get_field_values();
        const saveData: IncomeCorrectSaveData = {
            variant: this.variant,
            kat: item.kat,
            cls: item.cls,
            pcl: item.pcl,
            spf: item.spf,
            note: item.note,
            variant_income_data: item.variant_income_data,
            sum_correct: item.sum_correct,
            field_values: field_values,
            user_name: this.$store.getters.user_uuid,
            year: this.repYear,
            curYear: this.repYear,
            is_correct_changed: item.is_correct_changed,
            is_income_changed: item.is_updated,
        }
        let result: any
        try {
            result = await fetch('/api-py/delete-income-correct-data', {
                method: 'POST',
                body: JSON.stringify(saveData)
            })
            if (result.status === 200) {
                result = await result.json();
                this.makeToast("success", "Результат сохранения", result.message);
                if (result.status == true) {
                    this.correct_data.delete_row(item);
                }
            } else {
                this.makeToast('danger', 'delete-income-correct-data', `${result.data} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса delete-correct-data', (error as Error).toString());
            return;
        }
    }

    private async save_correct_row(item: IncomeCorrectRowClass, is_all?: boolean) {
        const field_values = item.get_field_values();
        const saveData: IncomeCorrectSaveData = {
            variant: this.variant,
            kat: item.kat,
            cls: item.cls,
            pcl: item.pcl,
            spf: item.spf,
            note: item.note,
            variant_income_data: item.variant_income_data,
            sum_correct: item.sum_correct,
            field_values: field_values,
            user_name: this.$store.getters.user_uuid,
            year: this.repYear,
            curYear: this.repYear,
            is_correct_changed: item.is_correct_changed,
            is_income_changed: item.is_updated,
        }
        let result: any
        try {
            result = await fetch('/api-py/save-income-correct-data', {
                method: 'POST',
                body: JSON.stringify(saveData)
            })
            if (result.status === 200) {
                result = await result.json();
                if (!is_all) {
                    this.makeToast("success", "Результат сохранения", result.message);
                }
                if (result.status == true) {
                    item.set_old_values_to_new_values();
                }
                await this.load_variant_income_data(item)
            } else {
                this.makeToast('danger', 'save-income-correct-data', `${result.status} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса save-income-correct-data', (error as Error).toString());
            return;
        }
    }

    get delta_sum(): number {
        let res = 0;
        for (const r of this.correct_data.rows) {
            if (!isNaN(r.delta)) {
                res = res + r.delta;
            }
        }
        return res;
    }

    get correct_sum(): number {
        let res = 0;
        for (const r of this.correct_data.rows) {
            if (!isNaN(r.sum_correct)) {
                res = res + r.sum_correct;
            }
        }
        return res;
    }

    private keyup13(event: any) {
        event.preventDefault();
        // Isolate the node that we're after
        const currentNode = event.target;
        // find all tab-able elements
        const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
        // Find the current tab index.
        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
        // focus the following element
        const targetIndex = (currentIndex + 1) % allElements.length;
        if (targetIndex < allElements.length) {
            allElements[targetIndex].select();
        }
    }// enter работает как tab

    // noAbc(evt: any) {
    //     // const regex = new RegExp('^-?[0-9]+$');\.?
    //     // const regex = new RegExp('^-?\\d*\\d{0,9}$');
    //     const regex = new RegExp('^-?\\d*\\.?$');
    //     // const key = String.fromCharCode(!evt.key ? evt.which : evt.key);
    //     const key = evt.key
    //     if (!regex.test(key)) {
    //         evt.preventDefault();
    //         return false;
    //     }
    // }// вводит только цифры

    private user_region() {
        if (this.listReg.length > 0) {
            this.region = this.listReg[0].code;
            // this.changeFilter(FilterEventOwner.region);
        } else {
            this.region = '';
        }
    }

    private get listOfYears() {
        return genListOfYears();
    }

    getVariantName(variant: any) {
        if (variant) {
            return variantName(variant);
        } else {
            return "Не выбран"
        }
    }

    private get listOfVariants() {
        return listForCorrect(this.listVariants, this.repYear);
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    async mounted() {
        await this.load_correct_fields();
        if (window.location.host === "localhost:10000") {
            this.region = '450101';
            this.variant = 'b5bbb151-d502-4ff7-b87d-95f44c6e8d26';
        }
        this.variants_loaded();
        // this.changeFilter(FilterEventOwner.year);
    }

    async created() {
        this.$watch('listReg', this.user_region);
        // this.$watch('listVariants', this.variants_loaded)
        this.load_user_access_level();
    }

    private async variants_loaded() {
        const queryString = new URL(document.location.href.replace("#", "")).search;
        const params = new URLSearchParams(queryString);
        const version = params.get("version");
        if (version) {
            const variant = await loadVariantByUiid(version);
            if (variant) {
                this.repYear = variant.year;
                if (variant.region_code) {
                    this.region = variant.region_code;
                    this.variant = variant.variant_uuid;
                    await this.changeFilter(FilterEventOwner.variant);
                }
                this.editable = variant.attribute && this.access_level > 1;
                if (!this.loading) this.load_income_correct();
            }
        }
    }

    @Watch("listVariants", {deep: true})
    async change_list_variants(oldvalue: any, newvalue: any) {
        this.variant = '';
        await this.find_actual_variant();
    }


    @Watch('region')
    @Watch('curYear')
    async change_region(oldvalue: any, newvalue: any) {
        this.correct_data.rows = [];
        this.variant = '';
        const filter = {
            variant: this.variant,
            year: this.repYear,
            region: this.region
        };
        await this.$emit('change_filter', filter);
        await this.find_actual_variant();

    }

    private async changeFilter(event_owner: FilterEventOwner) {
        if (event_owner === FilterEventOwner.year || event_owner === FilterEventOwner.region)  {
            this.variant = ""
        }
        const filter = {
            variant: this.variant,
            year: this.repYear,
            region: this.region
        };
        this.$emit('change_filter', filter);
        await this.find_actual_variant();
        // if (this.variant != '') {
        //     const select_variant = this.getVariant(this.variant);
        //     if (select_variant) {
        //         this.editable = select_variant.attribute && this.access_level > 1;
        //         this.load_income_correct();
        //     }
        // }
    }

    private async find_actual_variant() {
        if (this.variant != '') {
            const select_variant = this.getVariant(this.variant);
            if (select_variant) {
                this.editable = select_variant.attribute && this.access_level > 1;
            }
            if (!this.loading) await this.load_income_correct();
        } else {
            for (const variant of this.listOfVariants.filter(value => (value.year == this.repYear && value.region_code == this.region))) {
                if (variant.attribute) {
                    this.variant = variant.variant_uuid;
                    const select_variant = this.getVariant(this.variant);
                    if (select_variant) {
                        this.editable = select_variant.attribute && this.access_level > 1;
                    }
                    if (!this.loading) await this.load_income_correct();
                }

            }
        }
    }

    private async load_income_correct() {
        this.correct_data.rows = [];
        this.loading = true;
        let loaded_corrected_data: BudgetIncomeCorrectRow[] = []
        try {
            const response = await fetch('/api-py/get-income-correct-by-version/' + this.variant);
            loaded_corrected_data = await response.json();
            for (const row of loaded_corrected_data) {
                this.correct_data.add_correct_data(row)
            }
            this.correct_data.sort_data_by_code();
            this.load_income_data();

        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса loadVariant', error.toString());
            return;
        } finally {
            this.loading = false;
        }
    }

    public async load_correct_fields() {
        try {
            const response = await fetch('/api-py/dictionary/budget_adjust/');
            this.correct_data.load_fields = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_correct_fields', error.toString());
            return;
        }
    }

    public async load_user_access_level() {
        try {
            const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/${this.module_code}`);

            const modules_access = await response.json();
            if (modules_access.length > 1) {
                this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
            }
            for (const ma of modules_access) {
                this.access_level = ma.access_level
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            return;
        }
    }

    private async addClk(obj: any) {
        const added_row = this.correct_data.add_ebk_code(obj);
        await this.load_ebk_names(added_row);
        if (await this.load_variant_income_data(added_row) == 0) {
            this.correct_data.rows.push(added_row)
        }
        await this.correct_data.sort_data_by_code()
    }

    private async load_income_data() {
        this.loading = true
        for (const row of this.correct_data.rows) {
            await this.load_variant_income_data(row)
            this.load_ebk_names(row);
        }
        await this.correct_data.sort_data_by_code();
        this.loading = false
    }

    private async load_variant_income_data(row: IncomeCorrectRowClass) {
        let loaded_income_data: BudgetIncomeData[] = []
        const kat = row.kat;
        const cls = row.cls;
        const spf = row.spf;
        const pcl = row.pcl;
        const year = this.repYear;
        const url = `/api-py/get-income-data-for-correct-by-variant/${this.variant}/${kat}/${cls}/${pcl}/${spf}/${year}`;
        try {
            const response = await fetch(url);
            loaded_income_data = await response.json();
            if (loaded_income_data.length > 0) {
                for (const dat of loaded_income_data) {
                    const find_row = this.correct_data.find_by_code(dat.kat, dat.cls, dat.pcl, dat.spf)
                    if (find_row) {
                        this.$set(find_row, 'prev_variant_income_data', dat.prev_variant_income_data);
                        this.$set(find_row, 'variant_income_data', dat.variant_income_data);
                        this.$set(find_row, 'old_variant_income_data', dat.variant_income_data);
                    } else {
                        const new_row = new IncomeCorrectRowClass();
                        new_row.kat = row.kat;
                        new_row.cls = row.cls;
                        new_row.pcl = row.pcl;
                        new_row.spf = row.spf;
                        new_row.names = row.names;
                        new_row.prev_variant_income_data = dat.prev_variant_income_data;
                        new_row.variant_income_data = dat.variant_income_data;
                        new_row.old_variant_income_data = dat.variant_income_data;
                        this.correct_data.rows.push(new_row);
                    }
                }
                return loaded_income_data.length
            } else {
                const find_row = this.correct_data.find_by_code(row.kat, row.cls, row.pcl, row.spf)
                if (find_row)
                    return 1
                else
                    return 0
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_variant_income_data', error.toString());
            return -1;
        }
    }

    async load_ebk_names(item: IncomeCorrectRowClass) {
        const req_data: EbkIncomeCodeRequest = {
            kat: item.kat,
            cls: item.cls,
            pcl: item.pcl,
            spf: item.spf
        }
        await fetch('/api-py/get-income-ebk-code', {
            method: 'POST',
            body: JSON.stringify(req_data)
        }).then((response) => response.json()).then((data) => {
            this.$set(item, "names", data.ebk_code);
        })
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    getRegion(regCode: string) {
        const res = this.listReg.find((value: any) => value.code == regCode);
        if (res) {
            return res;
        } else {
            return {
                name_ru: "Не выбран"
            };
        }
    }

    private getVariantUidName(variant_uid: string) {
        const vr = this.listOfVariants.find(value => value.variant_uuid == variant_uid);
        return this.getVariantName(vr)
    }

    private getVariant(variant_uid: string) {
        return this.listOfVariants.find(value => value.variant_uuid == variant_uid);
    }

}
